import React, { Component } from 'react'
import Clipboard from 'react-clipboard.js'
import { Controlled as CodeMirror } from 'react-codemirror2'
import Markdown from 'react-markdown'
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/shell/shell'
import 'codemirror/mode/css/css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/php/php'
import 'codemirror/mode/python/python'
import 'codemirror/mode/sql/sql'

const codeMirrorMap = {
  Bash: 'text/x-sh',
  CSS: 'text/css',
  HTML: 'text/html',
  Javascript: 'text/javascript',
  PHP: 'text/x-php',
  Python: 'text/x-python',
  SQL: 'text/x-pgsql'
}

class Snippet extends Component {
  constructor (props) {
    super(props)
    this.state = {
      codeMirrorOptions: {
        mode: codeMirrorMap[this.props.snippet.language],
        lineNumbers: true,
        readOnly: true,
        lineWrapping: true
      },
      noteCodeMirrorOptions: {},
      snippet: this.props.snippet
    }

    this.handleSnippetClick = this.handleSnippetClick.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
    this.handleNoteChange = this.handleNoteChange.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    // Handle updates from App
    if (nextProps.snippet !== this.state.snippet) {
      this.setState({ snippet: nextProps.snippet })
    }

    this.setState({
      codeMirrorOptions: {
        mode: codeMirrorMap[nextProps.snippet.language],
        lineNumbers: true,
        readOnly: !nextProps.editing,
        lineWrapping: true
      },
      noteCodeMirrorOptions: {
        mode: 'text/x-markdown',
        lineNumbers: false,
        readOnly: !nextProps.editing,
        lineWrapping: true
      }
    })
  }

  handleSnippetClick (e) {
    this.props.handleSnippetClick(e)
  }

  handleInputChange (e) {
    const newSnippet = this.state.snippet
    newSnippet[e.target.name] = e.target.value.slice(0, 144)
    this.setState({
      snippet: newSnippet
    })
  }

  handleCodeChange (editor, data, value) {
    const newSnippet = this.state.snippet
    newSnippet.code = value
    this.setState({
      snippet: newSnippet
    })
  }

  handleNoteChange (editor, data, value) {
    const newSnippet = this.state.snippet
    newSnippet.notes = value
    this.setState({
      snippet: newSnippet
    })
  }

  render () {
    const filterSelects = []
    let snippetClass = (this.props.editing) ? 'snippet snippet--editing' : 'snippet'
    if (this.state.snippet.code === '') snippetClass += ' snippet--notes-only'
    if (this.state.snippet.notes === '') snippetClass += ' snippet--code-only'

    // Render CodeMirror for notes only in edit mode
    const codeMirrorNotes = (this.props.editing) ? <CodeMirror name='notes' value={this.state.snippet.notes} options={this.state.noteCodeMirrorOptions} onBeforeChange={this.handleNoteChange} /> : ''

    // Editing buttons
    const editButtonIcon = (this.props.editing) ? <i className='fas fa-ban' /> : <i className='fas fa-edit' />

    const editButton = <button className='icon icon--edit' name='edit' onClick={this.handleSnippetClick}>{editButtonIcon}</button>
    const deleteButton = <button className='icon icon--delete' name='delete' onClick={this.handleSnippetClick}><i className='fas fa-trash-alt' /></button>
    const saveButton = <button className='icon icon--save' name='save' onClick={this.handleSnippetClick}><i className='fas fa-save' /></button>

    // I went way overkill on this to make just two selects, feel free to refactor
    if (Object.keys(this.props.allFilters).length > 0) {
      for (var category of Object.keys(this.props.allFilters)) {
        if (category !== 'user') {
          const opts = []
          for (var filter of Object.keys(this.props.allFilters[category])) {
            if (filter === this.state.snippet[category]) {
              opts.push(<option key={filter} value={filter} selected>{filter}</option>)
            } else {
              opts.push(<option key={filter} value={filter}>{filter}</option>)
            }
          }
          filterSelects.push(
            <div className='snippet__filter' key={category}>
              <label>{category}</label>
              <span className='snippet__filter-tag'>{this.props.snippet[category]}</span>
              <select className='snippet__filter-select' name={category} onChange={this.handleInputChange}>
                {opts}
              </select>
            </div>
          )
        }
      }
    }

    return (
      <div className={snippetClass}>
        <div className='actionable-buttons'>
          <div className='button-container'>
            <Clipboard className='button' data-clipboard-text={this.props.snippet.code}><i className='fas fa-code' />Copy Code</Clipboard>
            <Clipboard className='button' data-clipboard-text={window.location.href.split('?')[0] + '?id=' + this.props.snippet.id}><i className='fas fa-link' />Copy Link</Clipboard>
          </div>

          <div className='icon-container'>
            {editButton} {deleteButton} {saveButton}
          </div>
        </div>

        <div className='snippet__details'>
          <div className='snippet__header'>
            <div className='snippet__name'> {this.state.snippet.name}</div>
            <p className='snippet__description'>{this.state.snippet.description}</p>

            <div className='snippet__edit'>
              <label>Title</label>
              <input className='snippet__name-input input' type='text' name='name' value={this.state.snippet.name} onChange={this.handleInputChange} />

              <label>Description</label>
              <input className='snippet__description-input input' type='text' name='description' value={this.state.snippet.description} onChange={this.handleInputChange} />
              <span className='snippet__character-count'>{(this.state.snippet.description) ? this.state.snippet.description.length : 0}/144 characters</span>
            </div>
          </div>
        </div>

        <div className='snippet__filter-container'>{filterSelects}</div>

        <div className='snippet__code'>
          <label>Code</label>
          <CodeMirror name='code' value={this.state.snippet.code} options={this.state.codeMirrorOptions} onBeforeChange={this.handleCodeChange} />
          <Clipboard className='icon' data-clipboard-text={this.props.snippet.code}><i className='fas fa-code' /></Clipboard>
        </div>

        <div className='snippet__notes'>
          <div className='snippet__notes-header'>Additional Notes <span className='snippet__notes-markdown-msg'>(markdown supported)</span></div>
          <Markdown className='snippet__notes-text' source={this.state.snippet.notes} />
          {codeMirrorNotes}
        </div>
      
        <div className='snippet__edit'>
          <button className="button button--save" name='save' onClick={this.handleSnippetClick}>Save <i className='fas fa-save' /></button>
        </div>
      </div>
    )
  }
}

export default Snippet
