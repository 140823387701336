import React, { Component } from 'react'

class Filters extends Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)

    this.state = {
      openCategories: {
        0: true
      }
    }
  }

  handleClick (e) {
    this.props.onFilterUpdate(e.target.getAttribute('data-category'), e.target.getAttribute('data-filter'))
  }

  render () {
    // Populate a list of active filters
    // (Eventually this should be by category & name)
    const activeFilters = []
    const activeFiltersDiv = []
    for (var category of Object.keys(this.props.activeFilters)) {
      for (var filter of this.props.activeFilters[category]) {
        activeFilters.push(filter)
        activeFiltersDiv.push(
          <div
            key={filter}
            className='filter__active'
            data-category={category}
            data-filter={filter}
            onClick={this.handleClick}
          >{filter}
          </div>)
      }
    }

    return (
      <div className='filters'>
        <div className='filters__active'>{activeFiltersDiv}</div>

        {Object.keys(this.props.allFilters).map((category, index) =>
          <div key={index} className={`filter__category ${this.state.openCategories[index] ? 'filter__category--open' : ''}`}>
            <div
              className='filter__header'
              onClick={() => {
                this.setState(prevState => {
                  return {
                    openCategories: {
                      ...prevState.openCategories,
                      [index]: !prevState.openCategories[index]
                    }
                  }
                }
                )
              }}
            >
              {category}
            </div>

            <div className='filter__group' key={category}>
              {Object.keys(this.props.allFilters[category]).map((filter) =>
                <div
                  key={filter}
                  className={(activeFilters.indexOf(filter) > -1) ? 'filter__name filter__name--active' : 'filter__name'}
                  data-category={category}
                  data-filter={filter}
                  onClick={this.handleClick}
                >
                  {filter}
                </div>
              )}

            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Filters
