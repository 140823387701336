import React, { Component } from 'react'

class Search extends Component {
  constructor (props) {
    super(props)
    this.handleSearchKeyUp = this.handleSearchKeyUp.bind(this)
  }

  handleSearchKeyUp () {
    this.props.handleSearchKeyUp(this.refs.searchContainer.value)
  }

  render () {
    return (
      <div className='search-bar'>
        <input className='search-bar__input input' ref='searchContainer' onChange={this.handleSearchKeyUp} name='searchBox' id='search' type='search' placeholder='Search by keyword' />
        <button className='search-bar__button'><i className='fas fa-search' /></button>
      </div>
    )
  }
}

export default Search
