import React, { Component } from 'react'
import firebase, { auth } from 'firebase/app' // eslint-disable-line
import 'firebase/auth'
import 'firebase/firestore'
import Filters from './components/Filters'
import Search from './components/Search'
import Results from './components/Results'
import Snippet from './components/Snippet'
import './assets/scss/App.scss'

// Initialize Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyCcv9LgjoGwlVTcgOpvOGWcJuDoR0aG5zY',
  databaseURL: 'https://trinity-toolbelt.firebaseio.com',
  storageBucket: 'trinity-toolbelt.appspot.com',
  authDomain: 'trinity-toolbelt.firebaseapp.com',
  messagingSenderId: '883782505203',
  projectId: 'trinity-toolbelt'
})
const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({
  prompt: 'select_account',
  hd: 'trinityinsight.com'
})

class App extends Component {
  constructor (props) {
    super(props)
    const db = firebase.firestore()
    db.settings({ timestampsInSnapshots: true })
    this.firestore = db
    this.dataInitialized = false

    this.state = {
      currentUser: '',
      allFilters: {},
      activeFilters: {},
      allSnippets: [],
      masterAllSnippets: [],
      selectedSnippet: {
        code: '',
        name: '',
        description: ''
      },
      selectedSnippetId: 'AXrHfMpavEpz6QBxycx1',
      editing: false
    }

    this.handleLogin = this.handleLogin.bind(this)

    // Filters
    this.handleFilterUpdate = this.handleFilterUpdate.bind(this)

    // Results
    this.handleResultClick = this.handleResultClick.bind(this)

    // Snippets
    this.handleSnippetEdit = this.handleSnippetEdit.bind(this)
    this.handleSnippetClick = this.handleSnippetClick.bind(this)
    this.handleSnippetNew = this.handleSnippetNew.bind(this)

    // Search
    this.handleSearchKeyUp = this.handleSearchKeyUp.bind(this)
  }

  handleSnippetClick (e) {
    const self = this
    if (e.target.name === 'edit') {
      const newEditingState = !(this.state.editing)
      this.setState({
        editing: newEditingState
      })
    } else if (e.target.name === 'save') {
      // Save to firestore
      console.log('save or update snippet condition = ', this.firestore.collection('snippets').doc(self.state.selectedSnippetId).get())
      if (this.firestore.collection('snippets').doc(self.state.selectedSnippetId)) {
        this.firestore.collection('snippets')
          .doc(this.state.selectedSnippetId)
          .set(this.state.selectedSnippet, { merge: true })
      } else {
        const newDoc = self.firestore.collection('snippets').doc()
        newDoc.set(self.state.selectedSnippet)
      }

      // Reflect the changes in results
      const newAll = this.state.allSnippets
      newAll[this.state.selectedSnippetId] = this.state.selectedSnippet
      this.setState({
        allSnippets: newAll,
        editing: false
      })
      // TODO remove editing after save? -mike
    } else if (e.target.name === 'delete') {
      this.firestore.collection('snippets').doc(this.state.selectedSnippetId).delete()
        .then(() => {
          const newAllSnippets = this.state.allSnippets
          const newMasterAllSnippets = this.state.masterAllSnippets
          newMasterAllSnippets.slice(this.state.selectedSnippet)
          newAllSnippets.slice(this.state.selectedSnippet)

          this.setState({
            allSnippets: newAllSnippets,
            selectedSnippet: newAllSnippets[0],
            selectedSnippetId: newAllSnippets[0].id,
            masterAllSnippets: newMasterAllSnippets[0],
            editing: !(this.state.editing)
          })
          const self = this
          setTimeout(function () {
            self.render()
          }, 0)

          // Re-load
          window.location.reload()
        })
    }
  }

  handleSnippetNew () {
    const newDoc = this.firestore.collection('snippets').doc()
    const newData = {
      id: newDoc.id,
      name: '',
      code: '',
      description: '',
      notes: '',
      user: this.state.currentUser,
      department: 'Any',
      language: 'Javascript'
    }
    // newDoc.set(newData).then((ref) => {
    const newAllSnippets = this.state.allSnippets
    newAllSnippets.push(newData)
    this.setState({
      editing: true,
      selectedSnippet: newData,
      selectedSnippetId: newData.id,
      allSnippets: newAllSnippets
    })
    // })
  }

  handleLogin (e) {
    firebase.auth().signInWithPopup(provider)
      .then(function (result) {
        console.log('RESULT: ' + result)
        const token = result.credential.accessToken
        document.cookie = '__session=' + token + ';max-age=3600'
        this.render()
      })
      .catch(function (error) {
        console.log('Error signing in:', error)
      })
  }

  handleSnippetEdit (e) {
    this.setState({
      selectedSnippet: e
    })
  }

  handleResultClick (e) {
    const selectedId = e.currentTarget.getAttribute('id')
    let selectedSnippet = {}
    for (var snip of this.state.allSnippets) {
      if (snip.id === selectedId) {
        selectedSnippet = snip
        break
      }
    }
    this.setState({
      selectedSnippetId: selectedId,
      selectedSnippet: selectedSnippet,
      editing: false
    })
  }

  handleFilterUpdate (category, filter) {
    // TODO: Most of this should be in Filters.jsx

    // Add or remove a filter when it's clicked
    const newFilters = this.state.activeFilters
    if (!newFilters[category]) {
      // The category doesn't exist in the filters, so just add it
      newFilters[category] = []
      newFilters[category].push(filter)
    } else {
      // The category does exist, so check if we should add or remove it
      const index = newFilters[category].indexOf(filter)
      if (index > -1) {
        newFilters[category].splice(index, 1)
      } else {
        newFilters[category].push(filter)
      }
    }
    this.setState({
      activeFilters: newFilters
    })
  }

  handleSearchKeyUp (searchValue) {
    const searchTerms = searchValue.split(' ').map(term => { return (term.length > 1) ? term.toLowerCase() : '' })
    const firstTerm = searchTerms[0] ? searchTerms[0] : ''
    const matchedSnippets = []
    const allSnippets = this.state.masterAllSnippets
    let snippetAdded = false

    // We want more than one letter to start returning results
    if (firstTerm.length > 1) {
      // Go through each snippet to match any words to snippet property, minus ID
      for (var snippet of allSnippets) {
        snippetAdded = false
        for (var attr of Object.keys(snippet)) {
          if ((!snippetAdded) && snippet[attr]) {
            for (var term of searchTerms) {
              if (snippet[attr].toLowerCase().indexOf(term) > -1) {
                matchedSnippets.push(snippet)
                snippetAdded = true
                break
              }
            }
          } else { break }
        }
      }
      this.setState({
        allSnippets: matchedSnippets
      })
    } else {
      this.setState({
        allSnippets: this.state.masterAllSnippets
      })
    }
  }

  getParameterByName (name, url) {
    if (!url) url = window.location.href
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  initializeData () {
    const firestoreFilters = {}
    const firestoreCategories = {}
    this.firestore.collection('filters')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          firestoreFilters[doc.id] = doc.data()
        })
        for (var category of Object.keys(firestoreFilters)) {
          firestoreCategories[category] = []
        }
        this.setState({
          allFilters: firestoreFilters,
          activeFilters: firestoreCategories
        })
      })

    const snippets = []
    let selectedSnippetIndex = 0
    const hasQuery = this.getParameterByName('id')
    let firstSnippet = ''
    let loopIndex = 0

    this.firestore.collection('snippets')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log('doc = %O', doc);
          const thisDoc = doc.data()

          if (hasQuery && thisDoc.id === hasQuery) {
            // This will be added to the front after we do the alphabetical sort.
            firstSnippet = doc
            selectedSnippetIndex = loopIndex
          } else {
            snippets.push({
              id: doc.id,
              name: thisDoc.name,
              code: thisDoc.code,
              description: thisDoc.description,
              notes: thisDoc.notes,
              user: thisDoc.user,
              partner: thisDoc.partner,
              department: thisDoc.department,
              language: thisDoc.language
            })
          }
          loopIndex++
        })
        snippets.sort(function (a, b) {
          if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim()) return -1
          if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) return 1
          return 0
        })
        if (firstSnippet) {
          console.log('firstSnippet = %O', firstSnippet)
          var thisDoc = firstSnippet.data()
          snippets.unshift({
            id: thisDoc.id,
            name: thisDoc.name,
            code: thisDoc.code,
            description: thisDoc.description,
            notes: thisDoc.notes,
            user: thisDoc.user,
            partner: thisDoc.partner,
            department: thisDoc.department,
            language: thisDoc.language
          })
        }
        this.setState({
          thisSnippet: snippets[selectedSnippetIndex],
          selectedSnippet: snippets[0],
          selectedSnippetId: snippets[0].id,
          allSnippets: snippets,
          masterAllSnippets: snippets
        })
      })
  }

  componentDidMount () {
    const self = this
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        self.setState({
          currentUser: user.displayName
        })
      }
    })
  }

  render () {
    if (this.state.currentUser) {
      if (!this.dataInitialized) {
        this.initializeData()
        this.dataInitialized = true
      }
      return (
        <div className='appWrapper'>
          <div className='top-bar'>
            <div className='top-bar__title'>Tool Time</div>
            <Search
              allSnippets={this.state.allSnippets}
              allFilters={this.state.allFilters}
              activeFilters={this.state.activeFilters}
              handleSearchKeyUp={this.handleSearchKeyUp}
            />
            <button className='button button--rounded' onClick={this.handleSnippetNew}>New Snippet</button>
          </div>
          <div className='sidebar'>
            <Filters
              allFilters={this.state.allFilters}
              activeFilters={this.state.activeFilters}
              onFilterUpdate={this.handleFilterUpdate}
            />
          </div>
          <main>
            <Results
              allFilters={this.state.allFilters}
              activeFilters={this.state.activeFilters}
              allSnippets={this.state.allSnippets}
              selectedSnippet={this.state.selectedSnippet}
              selectedSnippetId={this.state.selectedSnippetId}
              handleResultClick={this.handleResultClick}
            />
            <Snippet
              allFilters={this.state.allFilters}
              snippet={this.state.selectedSnippet}
              editing={this.state.editing}
              changeHandler={this.handleSnippetEdit}
              handleSnippetClick={this.handleSnippetClick}
            />
          </main>
        </div>
      )
    } else {
      return (
        <div className='appWrapper'>
          <main className='sign_in'>
            <img src='https://s3.amazonaws.com/clientinstalls/TrinityInsight/trinity-80s.png' alt='' />
            <button className='button' onClick={this.handleLogin}>Login</button>
          </main>
        </div>
      )
    }
  }
}

export default App
