import React, { Component } from 'react'
import Clipboard from 'react-clipboard.js'

class Results extends Component {
  constructor (props) {
    super(props)
    this.handleResultClick = this.handleResultClick.bind(this)
  }

  handleResultClick (e) {
    this.props.handleResultClick(e)
  }

  render () {
    let activeSnippets = []
    const activeSnippetIds = []
    let atLeastOneFilter = false
    let numberOfFilterCategories = 0
    const activeFilterCategories = {}
    let allCategoriesMatch = true
    let aValueContainsThis = false

    for (const [key, value] of Object.entries(this.props.activeFilters)) {
      if (value.length) {
        numberOfFilterCategories++
        activeFilterCategories[key] = value
      }
    }

    if (numberOfFilterCategories < 2) {
      // This is awful
      if (Object.keys(this.props.activeFilters).length > 0) {
        for (var category of Object.keys(this.props.activeFilters)) {
          for (var filter of this.props.activeFilters[category]) {
            for (var snippet of this.props.allSnippets) {
              atLeastOneFilter = true
              if (snippet[category] === filter && activeSnippetIds.indexOf(snippet.id) === -1) {
                activeSnippetIds.push(snippet.id)
                activeSnippets.push(snippet)
              }
            }
          }
        }
      } else {
        activeSnippets = this.props.allSnippets
      }
      if (!atLeastOneFilter) {
        activeSnippets = this.props.allSnippets
      }
    } else {
      for (const snippet of this.props.allSnippets) {
        allCategoriesMatch = true

        for (const [key, value] of Object.entries(activeFilterCategories)) {
          aValueContainsThis = false

          for (const tagName of value) {
            if (snippet[key] === tagName) {
              aValueContainsThis = true
            }
          }

          if (!aValueContainsThis) {
            allCategoriesMatch = false
          }
        }

        if (allCategoriesMatch) {
          activeSnippetIds.push(snippet.id)
          activeSnippets.push(snippet)
        }
      }
    }

    return (
      <div className='results__container'>
        <div className=''>
          {activeSnippets.map((snippet) =>
            <div key={snippet.id} className={(snippet.id === this.props.selectedSnippetId) ? 'result result--active' : 'result'} id={snippet.id} onClick={this.handleResultClick}>
              <p className='result__title'> {snippet.name}</p>
              <p className='result__author'> {snippet.user}</p>
              <p className='result__description'> {snippet.description}</p>

              <div className='button-container'>
                <Clipboard className='button button--inverse' data-clipboard-text={snippet.code}><i className='fas fa-code' />Copy Code</Clipboard>
                <Clipboard className='button button--inverse' data-clipboard-text={window.location.href.split('?')[0] + '?id=' + snippet.id}><i className='fas fa-link' />Copy Link</Clipboard>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Results
